import React, { useState } from 'react';
import styled from 'styled-components';
import Modal from '@mui/material/Modal';
import {
    Box,
    FormGroup,
    Grid
} from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';

import { DescriptionStruct } from './PaperCard';
import { boldSpecificDescriptions, boldGeneralDescriptions } from '../utils';

const FeedbackBox = styled(Box)(({ theme }) => ({
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    padding: theme.spacing(2, 4, 3),
    outline: 0,
}));

const FieldBox = styled(Box)(({ theme }) => ({
    padding: theme.spacing(0, 0, 2),
}));

interface FeedbackModalProps {
    currDescription: DescriptionStruct | null;
    open: boolean;
    closeModal: (showThankYou: boolean) => void;
    updateDescription: (successCallback: () => void) => void;
    generateNewDescription: () => void;
    submitDescriptionFeedback: (feedback: DescriptionFeedbackStruct) => void;
    newDescription: DescriptionStruct | null;
    isWaiting: boolean;
    isSavingNewDescription: boolean;
    errorMessage: string | null;
    topic: string;
    isSpecific?: boolean;
}

export interface DescriptionFeedbackStruct {
    isGood: boolean;
    whyBad: string | null;
    email: string;
    isPaperSpecific: boolean;
}

const reasons = [
    {
        label: 'The generated description is inaccurate or hallucinating.',
        value: 'hallucinating'
    },
    {
        label: 'Too high level',
        value: 'too_high_level'
    },
    {
        label: 'Too specific to the paper (not general enough)',
        value: 'too_paper_specific'
    },
    {
        label: 'Difficult to understand',
        value: 'difficult_to_understand'
    },
    {
        label: 'Doesn\'t contain info I\'m looking for',
        value: 'not_what_looking_for'
    },
]

const FeedbackModal = ({currDescription, open, newDescription, isWaiting, isSavingNewDescription, errorMessage, topic,
    isSpecific, closeModal, updateDescription, generateNewDescription, submitDescriptionFeedback}: FeedbackModalProps) => {
    const [feedbackSumitted, setFeedbackSubmitted] = useState(false);
    const [checked, setChecked] = useState<boolean[]>(Array(reasons.length).fill(false));
    const [checkedOther, setCheckedOther] = useState<boolean>(false);
    const [otherDescription, setOtherDescription] = useState<string|null>(null);
    const [email, setEmail] = useState<string>('');

    const submitFeedback = () => {
        const whyBadList = reasons.filter((reason, idx) => checked[idx]).map((reason) => reason.value);
        if (checkedOther) {
            whyBadList.push(`[[other]] ${otherDescription}`);
        }
        const whyBad = whyBadList.join(', ');
        submitDescriptionFeedback({
            isGood: false,
            whyBad,
            email:email,
            isPaperSpecific: isSpecific || false,
        });
        setFeedbackSubmitted(true);
    }

    const handleClose = () => {
        setFeedbackSubmitted(false);
        closeModal(feedbackSumitted || newDescription !== null);
    }

    const useNewDescription = () => {
        updateDescription(handleClose);
    }

    const getRightPanel = () => {
        let rightPane = null;
        if (isWaiting) {
            rightPane = (
                <div>
                    <h6>Generating new description...</h6>
                    <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        <CircularProgress />
                    </Box>
                </div>
            )
        } else if (newDescription?.id !== null) {
            rightPane = (
                <div>
                    <h6>New description</h6>
                    <p>{boldGeneralDescriptions(newDescription?.general || '', topic)}</p>
                    {isSpecific && (<p>{boldSpecificDescriptions(newDescription?.specific || '')}</p>)}
                    {isSavingNewDescription
                        ? (
                            <Button variant="outlined" onClick={useNewDescription} disabled>Saving description ...</Button>
                        ) : (
                            <Button variant="outlined" onClick={useNewDescription} data-heap-ref="use-new-description">Use new description</Button>
                        )
                    }
                </div>
            )
        } else if (!feedbackSumitted) {
            rightPane = (
                <div>
                    <h6>Send feedback to the Semantic Scholar team</h6>
                    <FormControl>
                        <FormLabel>Why is this not a good description?</FormLabel>
                        <FormGroup>
                            {reasons.map((reason, idx) => (
                                <FormControlLabel key={`checkbox-${idx}`} control={
                                    <Checkbox name={reason.value}
                                        checked={checked[idx]}
                                        onChange={ () => {
                                            const newChecked = [...checked];
                                            newChecked[idx] = !newChecked[idx];
                                            setChecked(newChecked);
                                        }}/>}
                                label={reason.label} />
                            ))}
                            <FormControlLabel key={`checkbox-other`} control={
                                <Checkbox name="other"
                                    checked={checkedOther}
                                    onChange={ () => {
                                        setCheckedOther(!checkedOther);
                                    }} /> }
                                label="Other" />
                        </FormGroup>
                    </FormControl>
                    {checkedOther && (
                        <FieldBox>
                            <TextField
                                fullWidth
                                label="Please specify"
                                multiline rows={4}
                                value={otherDescription}
                                onChange={(e) => setOtherDescription(e.target.value)}
                            />
                        </FieldBox>)
                    }
                    <FieldBox>
                        <TextField
                            fullWidth
                            label="Email address"
                            helperText="Please leave your email address if you would like to be contacted about your feedback."
                            size="small"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </FieldBox>
                    <Button variant="contained" onClick={submitFeedback}>Submit feedback</Button>
                </div>
            )
        } else if (feedbackSumitted) {
            rightPane = (
                <div>
                    <h6>Thank you for your feedback!</h6>
                    <p>You can try generate a new description for this topic based on the same paper.</p>
                </div>
            )
        }
        return rightPane
    }
    return (
        <Modal open={open} onClose={handleClose}>
            <FeedbackBox sx={{ bgcolor: 'background.paper', border: '1px solid #000' }}>
                {errorMessage && (
                    <Alert severity="error" variant="outlined">{errorMessage}</Alert>
                )}
                <Grid container spacing={4}>
                    <Grid item xs={6}>
                        <h6>Current description</h6>
                        <p>{boldGeneralDescriptions(currDescription?.general || '', topic)}</p>
                        {isSpecific && (<p>{boldSpecificDescriptions(currDescription?.specific || '')}</p>)}
                        {!newDescription?.id && (
                            <Button variant="outlined" onClick={generateNewDescription} data-heap-ref="generate-new-description">
                                Generate new description
                            </Button>
                        )}
                        {newDescription?.id && (
                            <Button variant="outlined" onClick={handleClose} data-heap-ref="keep-current-description">Keep current description</Button>
                        )}
                    </Grid>
                    <Grid item xs={6}>
                        {getRightPanel()}
                    </Grid>
                </Grid>
            </FeedbackBox>
        </Modal>
    )
}

export default FeedbackModal;