import axios from "axios";

const S2_USER_URL = "https://www.semanticscholar.org/api/1/user";
const DEV_HEAP_ID = '807978237';
const PRODUCTION_HEAP_ID = '572793565';

export const analyticsStore = (() => {
    let userId: number | null = null;
    let userHash: number | null = null;

    // TODO: Change to the the product heap id when we go live
    const heapId: string = process.env.NODE_ENV === 'production' ? PRODUCTION_HEAP_ID : DEV_HEAP_ID;
    return {
        getUserId: () => userId,
        init: () => {
            axios.get(S2_USER_URL, { withCredentials: true }).then((res) => {
                userId = res.data.user.id
                userHash = res.data.user.hash
            }).catch((err) => {
                console.log(err);
            }).finally(() => {
                const heapSnippet = `
                    window.heap=window.heap||[],heap.load=function(e,t){window.heap.appid=e,window.heap.config=t=t||{};var r=document.createElement("script");r.type="text/javascript",r.async=!0,r.src="https://cdn.heapanalytics.com/js/heap-"+e+".js";var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(r,a);for(var n=function(e){return function(){heap.push([e].concat(Array.prototype.slice.call(arguments,0)))}},p=["addEventProperties","addUserProperties","clearEventProperties","identify","resetIdentity","removeEventProperty","setEventProperties","track","unsetEventProperty"],o=0;o<p.length;o++)heap[p[o]]=n(p[o])};
                    heap.load('${heapId}');
                    ${userHash && `heap.identify(${userHash}, 'user_hash');`}
                    ${userId && `heap.addUserProperties({ userId: ${userId} });`}
                `;
                //@ts-ignore
                const script = document.createElement('script');
                script.setAttribute('type', 'text/javascript');
                script.innerHTML = heapSnippet;
                document.body.appendChild(script);
            });
        }
    }
})();
