/**
 * This is the top-level component that defines your UI application.
 *
 * This is an appropriate spot for application wide components and configuration,
 * stuff like application chrome (headers, footers, navigation, etc), routing
 * (what urls go where), etc.
 *
 * @see https://github.com/reactjs/react-router-tutorial/tree/master/lessons
 */

import React, { useState } from 'react';
import styled from 'styled-components';
import { Route, Link, Routes, useLocation } from 'react-router-dom';
import { Content, Footer } from '@allenai/varnish2/components';
import { Box, List, Drawer, ListItem, ListItemButton } from '@mui/material';
import Header from './components/Header';
import { Typography } from '@mui/material';
import { About } from './pages/About';
import { Topic } from './pages/Topic';
import { AppRoute } from './AppRoute';

import { useTheme } from '@mui/material/styles';

/**
 * An array capturing the available routes in your application. You can
 * add or remove routes here.
 */
const ROUTES: AppRoute[] = [
    {
        path: '/',
        label: 'About',
        Component: About,
    },
    {
        path: '/topic/:topicId',
        label: 'Topic',
        Component: Topic,
    }
];

export const App = () => {
    // Used to query the current page the user is on
    const location = useLocation();

    // Used to open and close the menu
    const [menuOpen, setMenuOpen] = useState(false);
    const handleMenuToggle = () => {
        setMenuOpen(!menuOpen);
    };

    const theme = useTheme();
    // @ts-ignore
    const bgColor = theme.color2.N2;

    return (
        <div style={{backgroundColor: "#E8ECF2"}}>
            <Header />
            <Box component="nav">
                <Drawer
                    variant="temporary"
                    anchor="right"
                    open={menuOpen}
                    onClose={handleMenuToggle}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile
                    }}>
                    <Menu>
                        {ROUTES.map(({ path, label }) => (
                            <ListItem key={path} disablePadding>
                                <Link to={path} onClick={handleMenuToggle}>
                                    <MenuButton selected={location.pathname === path}>
                                        {label}
                                    </MenuButton>
                                </Link>
                            </ListItem>
                        ))}
                    </Menu>
                </Drawer>
            </Box>
            <Content main>
                <Routes>
                    {ROUTES.map(({ path, Component }) => (
                        <Route key={path} path={path} element={<Component />} />
                    ))}
                </Routes>
            </Content>
            {
            <Footer backgroundColor={bgColor}>
                <Typography variant="subtitle1">
                    Some text on this page has been prepared by artificial intelligence. Its output may in some situations result in incorrect output that does not accurately reflect real people, places, or facts.
                </Typography>
                <Typography variant="subtitle1">
                    Please evaluate the accuracy of any output as appropriate for your use case, including by using human review of the output.
                </Typography>
                <div style={{marginTop: '10px'}}>
                    <a href="https://allenai.org/">© The Allen Institute for Artificial Intelligence</a> - All Rights Reserved | <a href="https://allenai.org/privacy-policy">Privacy Policy</a>| <a href="https://allenai.org/terms">Terms of Use</a> | <a href="https://allenai.org/business-code-of-conduct">Business Code of Conduct</a>
                </div>
            </Footer>
            }
        </div>
    );
};

const Menu = styled(List)`
    && {
        margin-top: ${({ theme }) => theme.spacing(3)};
    }
`;

const MenuButton = styled(ListItemButton)`
    && {
        min-width: 180px;
        max-width: 240px;
    }
`;

const SimpleLogo = styled.div`
    border-radius: 25px;
    width: 53px;
    height: 53px;
    line-height: 1;
    font-size: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    background: ${({ theme }) => theme.color.B2};
`;
