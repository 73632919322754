import React from 'react';
import { Box, Toolbar, Typography } from '@mui/material';

import S2Logo from './S2Logo';
import { Link } from 'react-router-dom';
import TopicSearch from './TopicSearch';

const Header = () => {
    return (
        <Toolbar sx={{ background: 'white', position: 'sticky', top: 0, padding: '4px 14px', borderBottom: '1px solid #e0e0e0', zIndex: 9999}}>
            <Link to="/" style={{ textDecoration: 'None', color: 'black'}}>
                <Box sx={{alignItems: 'center', display: 'flex'}}>
                <S2Logo />
                <div style={{textAlign: 'center'}}><strong>Topic Page Beta</strong></div>
                </Box>
            </Link>
            <Box sx={{padding: '0 14px', width: '90%'}}>
                <TopicSearch isSmall={true} apiUrl="/api/search"/>
            </Box>
        </Toolbar>
    )
}

export default Header;