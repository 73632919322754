import React from 'react';
import {
    Box,
    Grid,
    Typography,
} from '@mui/material';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import CircularProgress from '@mui/material/CircularProgress';
import styled from 'styled-components';

import IsGoodDescription, { PaperInteractiveState } from './IsGoodDescription';
import TooltipButton from './TooltipButton';
import { URL_UTM_SUFFIX, TLDR_EXPLANATION, AI_GENERATED_EXPLANATION } from '../constants';
import { boldSpecificDescriptions, boldGeneralDescriptions } from '../utils';

const Card = styled(Box)(({ theme }) => ({
    backgroundColor: '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(0),
    textAlign: 'left',
    border: 0,
    paddingBottom: theme.spacing(2),
    borderBottom: '1px solid #eee',
    boxShadow: 'none',
    color: theme.palette.text.secondary,
}));

const DescriptionBox = styled(Alert)(({ theme }) => ({
    '& .MuiAlert-message': {
        width: '100%',
    }
}));

const LoadingBox = styled(Box)(({ theme }) => ({
    justifyContent: 'center',
    display: 'flex',
    padding: theme.spacing(2, 0, 2),
}));

const ClickableLink = styled.a(({ theme }) => ({
    cursor: 'pointer',
}));

export interface PaperStruct {
    id: number;
    title: string;
    authors: { authorId: string; name: string }[];
    venue: string;
    year: number;
    tldr?: string;
    url: string;
    images?: { src: string } [];
    abstract: string;
    description: DescriptionStruct | null;
}

export interface RecentPaperStruct {
    id: number;
    title: string;
    authors: { authorId: string; name: string }[];
    venue: string;
    year: number;
    tldr?: string;
    url: string;
    abstract: string;
    description: DescriptionStruct | null;
}

export interface DescriptionStruct {
    id: number | null;
    general: string;
    specific: string | null;
}

interface PaperCardProps {
    paper: PaperStruct | RecentPaperStruct;
    topic: string;
    interactive?: boolean;
    showDescription?: boolean;
    errorMessage?: string | null;
    interactiveState?: PaperInteractiveState;
    toggleDescription?: () => void;
    handleYes?: () => void;
    handleNo?: () => void;
    isLoading?: boolean;
}

const authorUrl = 'https://www.semanticscholar.org/author';

const PaperCard = ({paper, topic, showDescription, errorMessage, interactive, interactiveState,
    toggleDescription, handleYes, handleNo, isLoading}: PaperCardProps) => {
    const [showAbstract, setShowAbstract] = React.useState(false);
    return (
        <Card>
            <Typography variant="body2" component="div" sx={{marginTop: '20px'}}>
                <a href={`${paper.url}?${URL_UTM_SUFFIX}`} target="_blank" data-heap-ref='listed-paper-link'>
                    {paper.title}
                </a>
            </Typography>
            <Typography variant="body1">
                {
                    paper.authors.map((author, idx) => (
                        <span key={`author-${author.authorId}`}>
                        <a href={`${authorUrl}/${author.authorId}?${URL_UTM_SUFFIX}`} target="_blank" data-heap-ref='author-link'>
                            {author.name}
                        </a>
                        {idx < paper.authors.length - 1 && ', '}</span>
                    ))
                }
                {paper.venue && (<span> • {paper.venue}</span>)}
                {paper.year && (<span> • {paper.year}</span>)}
            </Typography>
            {paper.tldr && (
                <p>
                    <TooltipButton label="TLDR (AI)" explanation={TLDR_EXPLANATION} />
                    <span style={{verticalAlign: 'middle'}}>{` ${paper.tldr} `}</span>
                    {!showAbstract && (
                        <ClickableLink onClick={() => setShowAbstract(!showAbstract)} data-heap-ref='expand-abstract'>Expand</ClickableLink>
                    )}
                </p>
            )

            }
            {(showAbstract || !paper.tldr) && (paper.abstract) && (
                <p>
                    <Button variant="text" size="small"
                        sx={{
                            marginRight: '2px',
                            backgroundColor: '#E8ECF2',
                            color: '#47515C',
                            fontWeight: 'normal',
                        }}
                    >Abstract</Button>
                    <span style={{verticalAlign: 'middle'}}>{` ${paper.abstract} `}</span>
                    {paper.tldr && (
                        <ClickableLink onClick={() => setShowAbstract(!showAbstract)}>Collapse</ClickableLink>
                    )}
                </p>
            )}
            {interactive && showDescription && (
                <DescriptionBox severity="info" icon={false}>
                    <Grid display="flex" justifyContent="space-between" alignItems="center">
                        <Typography variant="body2">{`How ${topic} is described in this paper`}</Typography>

                    </Grid>
                    {isLoading
                        ? (
                            <LoadingBox>
                                <CircularProgress />
                            </LoadingBox>
                        ) : <>
                            <p><TooltipButton label="Topic definition (AI)" explanation={AI_GENERATED_EXPLANATION} />
                                <span style={{verticalAlign: 'middle'}}>
                                    {boldGeneralDescriptions(paper.description?.general || '', topic)}
                                </span>
                            </p>
                            <p><TooltipButton label="Context in paper (AI)" explanation={AI_GENERATED_EXPLANATION} />
                                <span style={{verticalAlign: 'middle'}}>
                                    {boldSpecificDescriptions(paper.description?.specific || '')}
                                </span>
                            </p>
                        </>


                    }
                    {
                        !isLoading && (
                        <Grid display="flex" justifyContent="space-between" alignItems="center">
                            <Button variant="text" size="small"
                                sx={{
                                    color: 'rgb(34, 51, 103)',
                                    textDecoration: 'underline'
                                }}
                                onClick={toggleDescription} data-heap-ref='hide-paper-description'>
                                Hide topic description
                            </Button>
                            <IsGoodDescription
                                interactiveState={interactiveState}
                                handleYes={handleYes}
                                handleNo={handleNo}
                                />
                        </Grid>
                    )}
                </DescriptionBox>
            )}
            {interactive && !showDescription && (
                <Box display="flex" justifyContent="space-between">
                    <Button variant="text"
                        sx={{
                            color: 'rgb(34, 51, 103)',
                            textDecoration: 'underline'
                        }}
                        size="small" onClick={toggleDescription} data-heap-ref='see-paper-description'>
                        {`See how this paper describes ${topic}`}
                    </Button>
                    {errorMessage && (
                       <Typography variant="body1" color="error">
                       {errorMessage}</Typography>
                    )}
                </Box>
            )}
        </Card>
    )
}

export default PaperCard;