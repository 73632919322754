import React, { useState, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import axios from 'axios';
import {
    Box,
    Chip,
    Stack,
    Grid,
    Link,
    Typography,
    Button,
} from '@mui/material';
import Divider from '@mui/material/Divider';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import CircularProgress from '@mui/material/CircularProgress';
import styled from 'styled-components';

import FeedbackModal, { DescriptionFeedbackStruct } from '../components/FeedbackModal';
import PaperCard, { PaperStruct, RecentPaperStruct, DescriptionStruct } from '../components/PaperCard';
import IsGoodDescription, { PaperInteractiveState } from '../components/IsGoodDescription';
import TooltipButton from '../components/TooltipButton';
import StickyFeedbackButton from '../components/StickyFeedbackButton';
import MissingPage from '../components/MissingPage';
import GeneralError from '../components/GeneralError';
import { analyticsStore } from '../analytics/analytics';
import { boldSpecificDescriptions, boldGeneralDescriptions } from '../utils';

import { URL_UTM_SUFFIX, TLDR_EXPLANATION, AI_GENERATED_EXPLANATION,
    AI_GENERATED_EXPLANATION_MAIN_PAPER, AI_GENERATED_EXPLANATION_GENERIC } from '../constants';

interface TopicStruct {
    id: number | null;
    slug: string;
    title: string;
    related_topics: {
        id: number;
        slug: string;
        title: string;
    }[]
}

const NUM_RECENT_PAPERS = 50;

const ClickableLink = styled.a(({ theme }) => ({
    cursor: 'pointer',
}));

export const Topic = () => {
    const { slug, topicId } = useParams();
    const location = useLocation();
    const [isLoading, setIsLoading] = useState(true);
    const [topic, setTopic] = useState<TopicStruct>({id: null, title: '', slug: '', related_topics: []});
    const [paperIdx, setPaperIdx] = useState(0);
    const [papers, setPapers] = useState<PaperStruct[]>([]);
    const [recentPapers, setRecentPapers] = useState<RecentPaperStruct[]>([]);
    const [showDescriptions, setShowDescriptions] = useState<boolean[]>([]);
    const [errorMessages, setErrorMessages] = useState<Array<string | null>>([]);
    const [isPaperLoadings, setIsPaperLoadings] = useState<boolean[]>([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [paperInteractiveStates, setPaperInteractiveStates] = useState<PaperInteractiveState[]>([]);
    const [descriptionInteractiveState, setDescriptionInteractiveState] = useState<PaperInteractiveState>(PaperInteractiveState.QUESTION);
    const [newDescription, setNewDescription] = useState<DescriptionStruct>({ id: null, general: '', specific: '' });
    const [isWaitingNewDescription, setIsWaitingNewDescription] = useState(false);
    const [isSavingNewDescription, setIsSavingNewDescription] = useState(false);
    const [modalErrorMessage, setModalErrorMessage] = useState<string | null>(null);
    const [tabValue, setTabValue] = useState(0);
    const [isRecentPapersLoading, setIsRecentPapersLoading] = useState(true);
    const [isModalSpecific, setIsModalSpecific] = useState(true);
    const [hasOftenCitedPapersTab, setHasOftenCitedPapersTab] = useState(false);
    const [showMainAbstract, setShowMainAbstract] = useState(false);
    const [errorCode, setErrorCode] = useState(-1);

    useEffect(() => {
        axios.get(`/api/topic/${topicId}`).then((res) => {
            const { topic, papers } = res.data;
            const modifiedPapers = papers.map((paper: any) => {
                const updatedDescription = paper.description ? {
                    id: paper.description.id,
                    general: paper.description.general,
                    specific: paper.description.specific,
                } : null;
                return { ...paper, description: updatedDescription };
            });
            document.title = topic.title;
            setTopic(topic);
            setPapers(modifiedPapers);
            setPaperInteractiveStates(new Array(papers.length).fill(PaperInteractiveState.QUESTION))
            setShowDescriptions(new Array(papers.length).fill(false));
            setErrorMessages(new Array(papers.length).fill(null));
            setIsPaperLoadings(new Array(papers.length).fill(false));
            if (papers.length > 1){
                setHasOftenCitedPapersTab(true);
            }
        }).catch((error) => {
            setErrorCode(error.response.status);
        }).finally(() => {
            setIsLoading(false);
        })
        axios.get(`/api/get-recent-papers/${topicId}`).then((res) => {
            setRecentPapers(res.data.recentPapers.slice(0, NUM_RECENT_PAPERS));
        }).catch((err) => {
            console.log(err)
        }).finally(() => {
            setIsRecentPapersLoading(false);
        })
    }, [location])
    const userId = analyticsStore.getUserId();

    const setPaperInteractiveStatesIdx = (idx: number, val: PaperInteractiveState) => {
        const newPaperInteractiveStates = [...paperInteractiveStates];
        newPaperInteractiveStates[idx] = val;
        setPaperInteractiveStates(newPaperInteractiveStates);
    }

    const setIsPaperLoadingsIdx = (idx: number, val: boolean) => {
        const newIsPaperLoadings = [...isPaperLoadings];
        newIsPaperLoadings[idx] = val;
        setIsPaperLoadings(newIsPaperLoadings);
    }

    const handleYes = (paperIdx: number) => {
        submitDescriptionFeedback({
            isGood: true,
            whyBad: null,
            email: '',
            isPaperSpecific: true,
        });
        setPaperIdx(paperIdx);
        setPaperInteractiveStatesIdx(paperIdx, PaperInteractiveState.THANK_YOU);
        setTimeout(() => { setPaperInteractiveStatesIdx(paperIdx, PaperInteractiveState.NONE); }, 3000);
    }

    const handleNo = (paperIdx: number) => {
        setPaperIdx(paperIdx);
        setIsModalOpen(true);
    }

    const handleDescriptionYes = () => {
        submitDescriptionFeedback({
            isGood: true,
            whyBad: null,
            email: '',
            isPaperSpecific: false,
        });
        setPaperIdx(0);
        setDescriptionInteractiveState(PaperInteractiveState.THANK_YOU);
        setTimeout(() => { setDescriptionInteractiveState(PaperInteractiveState.NONE); }, 3000);
    }

    const handleDescriptionNo = () => {
        setPaperIdx(0);
        setIsModalOpen(true);
    }

    const closeModal = (showThankYou: boolean) => {
        setIsModalOpen(false);
        setNewDescription({ id: null, general: '', specific: '' });
        if (showThankYou && isModalSpecific) {
            setPaperInteractiveStatesIdx(paperIdx, PaperInteractiveState.THANK_YOU);
            setTimeout(() => { setPaperInteractiveStatesIdx(paperIdx, PaperInteractiveState.NONE); }, 3000);
        }
        if (showThankYou && !isModalSpecific) {
            setDescriptionInteractiveState(PaperInteractiveState.THANK_YOU);
            setTimeout(() => { setDescriptionInteractiveState(PaperInteractiveState.NONE); }, 3000);
        }
        setIsModalSpecific(true);
        setPaperIdx(0);
    }

    const updateDescription = (successCallback: () => void) => {
        setIsSavingNewDescription(true);
        axios.post(
            `/api/set-active-description/${newDescription.id}`,
        ).then((res) => {
            successCallback();
            const newPapers = [...papers];
            newPapers[paperIdx].description = {id: newDescription.id, general: newDescription.general, specific: newDescription.specific};
            setPapers(newPapers);
        }).catch((err) => {
            setModalErrorMessage('Fail to save the description. Please try again.');
            setTimeout(() => {
                setModalErrorMessage(null);
            }, 3000);
        }).finally(() => {
            setIsSavingNewDescription(false);
        });
    }

    const submitDescriptionFeedback = (feedback: DescriptionFeedbackStruct) => {
        // If the submission fails, let it fail silently.
        axios.post(
            `/api/submit-description-feedback/${papers[paperIdx].description?.id}`,
            { ...feedback, userId, }
        )
    }

    const generateNewDescription = () => {
        setIsWaitingNewDescription(true);
        setModalErrorMessage(null);
        axios.post(
            `/api/generate-description/${topic.id}/${papers[paperIdx].id}`,
            { userId, }
        ).then((res) => {
            setNewDescription(res.data);
        }).catch((err) => {
            const modalErrorMessage = 'Fail to generate a description. Please try again.';
            setModalErrorMessage(modalErrorMessage);
            setTimeout(() => {
                setModalErrorMessage(null);
            }, 3000);
        }).finally(() => {
            setIsWaitingNewDescription(false);
        });
    }

    const toggleShowDescription = (idx: number) => {
        if (!showDescriptions[idx] && !papers[idx].description) {
            setIsPaperLoadingsIdx(idx, true);
            axios.post(
                `/api/topic-paper-description/${topic.id}/${papers[idx].id}`
            ).then((res) => {
                const newPapers = [...papers];
                newPapers[idx].description = {id: res.data.id, general: res.data.general, specific: res.data.specific};
                setPapers(newPapers);
            }).catch((err) => {
                const newErrorMessages = [...errorMessages];
                newErrorMessages[idx] = 'Fail to generate a description. Please try again.';
                setErrorMessages(newErrorMessages);
                setTimeout(() => {
                    const newErrorMessages = [...errorMessages];
                    newErrorMessages[idx] = null;
                    setErrorMessages(newErrorMessages)
                }, 3000);
                const newShowDescriptions = [...showDescriptions];
                newShowDescriptions[idx] = false;
                setShowDescriptions(newShowDescriptions);
            }).finally(() => {
                setIsPaperLoadingsIdx(idx, false);
            });
        }
        const newShowDescriptions = [...showDescriptions];
        newShowDescriptions[idx] = !newShowDescriptions[idx];
        setShowDescriptions(newShowDescriptions);
    }

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setTabValue(newValue);
    };

    const getPaperLabel = (paper: PaperStruct): string => {
        let label = '';
        let suffix = [];
        if (paper.authors.length > 0) {
            let authorString = ''
            authorString += paper.authors[0].name.split(' ').slice(-1);
            if (paper.authors.length > 1) {
                authorString += ' et al.';
            }
            suffix.push(authorString);
        }

        if (paper.venue) {
            suffix.push(paper.venue);
        }

        if (paper.year) {
            suffix.push(paper.year);
        }
        return label + (suffix.length > 0 ? ` (${suffix.join(', ')})` : '');
    }

    const mainPaper = papers.length > 0 ? papers[0] : null;

    return (
        <div>
            {isLoading && (
                <Box sx={{display: 'flex', justifyContent: 'center', height: '80vh', alignItems: 'center'}}>
                    <div>
                        <CircularProgress size={80} />
                    </div>
                </Box>
            )}

            {!isLoading && errorCode === 404 && (
                <MissingPage />
            )}
            {!isLoading && errorCode === 500 && (
                <GeneralError />
            )}
            {topic.id !== null && (
            <div>
                <FeedbackModal
                    currDescription={papers[paperIdx]?.description || null}
                    open={isModalOpen}
                    closeModal={closeModal}
                    updateDescription={updateDescription}
                    newDescription={newDescription}
                    generateNewDescription={generateNewDescription}
                    submitDescriptionFeedback={submitDescriptionFeedback}
                    isWaiting={isWaitingNewDescription}
                    isSavingNewDescription={isSavingNewDescription}
                    errorMessage={modalErrorMessage}
                    topic={topic.title}
                    isSpecific={isModalSpecific}
                />
                <Typography variant="h2" sx={{marginTop: '20px'}}>{topic.title}</Typography>

                <Grid container spacing={5} sx={{marginBottom: '40px'}}>
                    <Grid item xs={12} sm={8}>
                        <Box>
                            {
                                mainPaper
                                ? (
                                    <Box sx={{background: '#fff', boxShadow: '0 1px 3px 0 rgba(0,0,0,.1)'}}>
                                        {
                                            <div style={{padding: '28px 28px'}}>
                                            <span data-heap-ref="ai-generated-label" >
                                                <TooltipButton label="Topic definition (AI)"
                                                    explanation={
                                                        mainPaper.id === -1
                                                        ? AI_GENERATED_EXPLANATION_GENERIC
                                                        : AI_GENERATED_EXPLANATION_MAIN_PAPER
                                                    }
                                                    labelSize='medium' />
                                            </span>
                                            <span style={{fontWeight: 'normal', fontSize: '1.125rem', lineHeight: '1.375rem', verticalAlign: 'middle'}}>
                                                {' '}{boldGeneralDescriptions(mainPaper?.description?.general || '', topic.title)}
                                                </span>
                                            {descriptionInteractiveState !== PaperInteractiveState.NONE &&
                                                <Grid display="flex" justifyContent="space-between" alignItems="center" sx={{padding: '5px 10px', marginTop: '10px', background: '#F8F9FA'}}>
                                                    <IsGoodDescription
                                                        interactiveState={descriptionInteractiveState}
                                                        handleYes={() => { handleDescriptionYes(); }}
                                                        handleNo={() => {
                                                            setIsModalSpecific(false);
                                                            handleDescriptionNo();
                                                        }}
                                                        isTopicOnly={true}
                                                    />
                                                </Grid>
                                            }
                                        </div>
                                        }

                                        <Divider >

                                        </Divider>
                                        {mainPaper.id != -1 &&
                                        <div style={{padding: '15px 28px 28px'}}>
                                            <Typography variant="caption">From a paper often cited for this topic</Typography>
                                            <div>
                                                <Typography variant="h6" sx={{marginTop: '0px', marginBottom: '0px'}}>
                                                    <a href={`${mainPaper.url}?${URL_UTM_SUFFIX}`} target="_blank" data-heap-ref="main-paper-link">
                                                    {papers[0].title}
                                                    </a>
                                                </Typography>
                                            </div>
                                            <Typography variant="subtitle1" sx={{marginTop: '5px'}}>
                                                {
                                                    papers[0].authors.map((author, idx) => (
                                                        <span key={`author-${author.authorId}`}>
                                                        <a href={`https://www.semanticscholar.org/author/${author.authorId}?${URL_UTM_SUFFIX}`} target="_blank" data-heap-ref='author-link'>
                                                            {author.name}
                                                        </a>
                                                        {idx < papers[0].authors.length - 1 && ', '}</span>
                                                    ))
                                                }
                                                {papers[0].venue && (<span> • {papers[0].venue}</span>)}
                                                {papers[0].year && (<span> • {papers[0].year}</span>)}
                                            </Typography>
                                            <div>
                                            {papers[0].tldr && !showMainAbstract && (
                                                <p>
                                                    <TooltipButton label="TLDR (AI)" explanation={TLDR_EXPLANATION} />
                                                    <span style={{verticalAlign: 'middle'}}>{` ${papers[0].tldr} `}</span>
                                                    {!showMainAbstract && (
                                                        <ClickableLink onClick={() => setShowMainAbstract(!showMainAbstract)} data-heap-ref='expand-abstract'>
                                                            Show abstract
                                                        </ClickableLink>
                                                    )}
                                                </p>
                                            )}
                                            {(showMainAbstract || !papers[0].tldr) && (papers[0].abstract) && (
                                                <p>
                                                    <Button variant="text" size="small"
                                                        sx={{
                                                            marginRight: '2px',
                                                            backgroundColor: '#E8ECF2',
                                                            color: '#47515C',
                                                            fontWeight: 'normal',
                                                        }}
                                                    >Abstract</Button>
                                                    <span style={{verticalAlign: 'middle'}}>{`${papers[0].abstract} `}</span>
                                                    {papers[0].tldr && (
                                                        <ClickableLink onClick={() => setShowMainAbstract(!showMainAbstract)}>Hide abstract</ClickableLink>
                                                    )}
                                                </p>
                                            )}
                                            </div>
                                            <div style={{paddingTop: '5px'}}>
                                                <div data-heap-ref="ai-generated-label" ><TooltipButton label="Context in paper (AI)" explanation={AI_GENERATED_EXPLANATION} />
                                                <span style={{verticalAlign: 'middle'}}>{' '}{boldSpecificDescriptions(mainPaper?.description?.specific || '')}</span>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    </Box>
                                ) : (
                                    <p>No description available. &#129760;</p>
                                )
                            }

                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        {topic.related_topics.length > 0 && (
                            <Box sx={{background: '#fff', padding: '28px', boxShadow: '0 1px 3px 0 rgba(0,0,0,.1)'}}>
                                <Typography variant="h4" component="div" sx={{marginTop: '0px'}}>Related Topics</Typography>
                                {
                                    topic.related_topics.map((t) => (
                                        <Link href={`./${t.id}`} key={`${t.slug}-${t.id}`} target='_blank'>
                                            <Chip label={t.title} variant="outlined" clickable={true} sx={{marginRight: '5px', marginBottom: '5px'}}  data-heap-ref='related-topic' />
                                        </Link>
                                    ))
                                }
                            </Box>
                        )}
                    </Grid>
                </Grid>
                <Box sx={{background: '#fff', padding: '20px 36px', boxShadow: '0 1px 3px 0 rgba(0,0,0,.1)'}}>
                    <Tabs value={tabValue} onChange={handleTabChange} aria-label="basic tabs example" sx={{ borderBottom: '1px solid #e0e0e0', fontSize: '1.1rem'}}>
                        {hasOftenCitedPapersTab && <Tab sx={{fontSize: '1.1rem'}} label={(papers[0].id != -1 ? "Other " : "") +"Papers Often Cited for This Topic"} value={0}/> }
                        <Tab sx={{fontSize: '1.1rem'}} label="Recent Papers on This Topic" value={hasOftenCitedPapersTab ? 1: 0} data-heap-ref="recent-papers-tab"/>
                    </Tabs>
                    {tabValue === 0 && hasOftenCitedPapersTab && (
                        <>
                        <Stack>
                            {
                                papers.slice(1).map((paper, idx) => (
                                    <PaperCard
                                        interactive
                                        key={`paper-card-${paper.id}`}
                                        paper={paper}
                                        topic={topic.title}
                                        showDescription={showDescriptions[idx+1]}
                                        interactiveState={paperInteractiveStates[idx+1]}
                                        toggleDescription={() => { toggleShowDescription(idx+1) }}
                                        handleYes={() => { handleYes(idx+1); }}
                                        handleNo={() => { handleNo(idx+1); }}
                                        isLoading={isPaperLoadings[idx+1]}
                                        errorMessage={errorMessages[idx+1]}
                                        />
                                ))
                            }
                        </Stack>
                        </>
                    )}
                    {((tabValue === 0 && !hasOftenCitedPapersTab) || (tabValue === 1)) && (
                        <>
                        {isRecentPapersLoading && (
                            <Box sx={{justifyContent: 'center', display: 'flex', paddingBottom: '16px', paddingTop: '24px'}}>
                                <CircularProgress />
                            </Box>
                        )}
                        {!isRecentPapersLoading && recentPapers.length === 0 && (
                            <p>No recent papers with matching title and abstract.</p>
                        )}
                        {!isRecentPapersLoading && recentPapers.length > 0  && (
                            <Stack>
                                {
                                    recentPapers.map((paper, idx) => (
                                        <PaperCard
                                            key={`recent-paper-card-${paper.id}`}
                                            topic={topic.title}
                                            paper={paper}
                                        />
                                    ))
                                }
                            </Stack>
                        )}
                        </>
                    )}

                    </Box>
            </div>)}
            <StickyFeedbackButton
                topic={topic.title}
            />
        </div>
    );
};
