import React from 'react';

const S2Logo = () => {
    return (
        <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 138.65 100" style={{height: '36px'}}>
            <defs>
                <style>{`
                .cls-1 {
                    fill: #f4d35e;
                    fill-rule: evenodd;
                }

                .cls-2 {
                    fill: #0f3875;
                }

                .cls-3 {
                    fill: #1857b6;
                }

                .cls-4 {
                    fill: #5492ef;
                }
                `}</style>
            </defs>
            <path className="cls-1" d="M138.65,30.44c-4.87,3.07-8.29,4.76-12.35,7.2-24,14.45-47.11,30.65-65,51.88L52.74,100,26.29,58c5.9,4.7,20.62,17.9,26.59,20.84L72.27,64.29C85.83,54.72,124,34,138.65,30.44Z"/>
            <path className="cls-2" d="M46.46,68.53c2,1.63,4,3.11,5.63,4.25A90.46,90.46,0,0,0,41.2,8.93l58.41-.82a84.91,84.91,0,0,1,7.47,30.6q2.57-1.3,5.08-2.54c-.62-10.78-3.67-22.55-9.56-36.17H22.89C40.2,20.46,48.05,45.28,46.46,68.53Z"/>
            <path className="cls-3" d="M42.93,65.66,44.57,67c-.81-19.65-8.18-39.83-22.14-57.11H10C27.88,26.41,38.83,46.28,42.93,65.66Z"/>
            <path className="cls-4" d="M38.6,61.9l1.86,1.55A110.35,110.35,0,0,0,13.1,21.89H0A147.81,147.81,0,0,1,38.6,61.9Z"/>
            </svg>

    )
}

export default S2Logo;