import React from 'react';
import {
    Box,
    Typography,
    Button,
} from '@mui/material';

const GeneralError = () => {
    return (
        <div style={{textAlign: 'center'}}>
            <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <img src="/generalError.png" />
            </Box>
            <Box>
                <Typography
                    sx={{ typography: {xs:'h5', md: 'h1'}}}
                    >Our servers are having a bit of trouble.</Typography>
            </Box>
            <Box>
                <Typography variant="body1">Our engineers are on the case and will have things back to normal shortly.</Typography>
            </Box>
            <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '32px'}}>
                <Button variant="contained" href="https://www.semanticscholar.org/">Go back to Semantic Scholar home page</Button>
            </Box>
        </div>
    )
};

export default GeneralError;