export const URL_UTM_SUFFIX = 'utm_source=topic_pages_prototype';
export const TLDR_EXPLANATION = 'TLDR (short for Too Long, Didn\'t Read) is an automatically generated short summary of a paper.';
export const AI_GENERATED_EXPLANATION = `
This description was generated with a Large Language Model (LLM)
 using relevant snippets from this paper.
`;

export const AI_GENERATED_EXPLANATION_GENERIC = `
This description was generated with a Large Language Model (LLM)
 using relevant snippets from papers often cited for this topic.
`;

export const AI_GENERATED_EXPLANATION_MAIN_PAPER = `
This description was generated with a Large Language Model (LLM)
 using relevant snippets from a paper often cited for this topic.
`;