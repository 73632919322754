import React from 'react';
import Fab from '@mui/material/Fab';

const StickyFeedbackButton = ({topic}: {topic: string}) => {
    return (
        <Fab color="primary" variant="extended"
            href={`https://docs.google.com/forms/d/e/1FAIpQLSe9S-UDMvg-DSSaufK0xQhcmuQ5-pxv0_k5NTlxPpBFGtXU2w/viewform?usp=pp_url&entry.433150009=${topic}`}
            target='_blank'
            sx={{
                position: 'fixed',
                bottom: '24px',
                right: '24px',
            }}
            data-heap-ref="google-forms-feedback"
            >
            Give feedback
        </Fab>
    )
}

export default StickyFeedbackButton;
