import React, { useEffect } from 'react';
import { MaxWidthText } from '@allenai/varnish2/components';
import StickyFeedbackButton from '../components/StickyFeedbackButton';
import TopicSearch from '../components/TopicSearch';

export const About = () => {
    useEffect(() => {
        document.title = 'Semantic Scholar Topic';
    }, []);
    return (
        <>
            <MaxWidthText>
                <h1>About Topic Pages Beta</h1>
                <p>
                    This is a prototype for Topic Page feature on Semantic Scholar. It's a work in progress and we're actively working on adding new topics and papers.

                </p>
                <p>
                    If you have a suggestion/feedback on topics and papers or features, please let us know using the Feedback button on the bottom right corner of the page.
                </p>
            </MaxWidthText>
            <StickyFeedbackButton topic="All topics"/>
        </>
    );
};
