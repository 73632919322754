import React from 'react';
import {
    Box,
    Typography,
    Button,
} from '@mui/material';
import { text } from 'stream/consumers';

const MissingPage = () => {
    return (
        <div style={{textAlign: 'center'}}>
            <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <img src="/404.png" />
            </Box>
            <Box>
                <Typography
                    sx={{ typography: {xs:'h5', md: 'h1'}}}
                    >We can't find the page you're looking for.</Typography>
            </Box>
            <Box>
                <Typography variant="body1">You may have mistyped the address or the page has been moved.</Typography>
            </Box>
            <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '32px'}}>
                <Button variant="contained" href="https://www.semanticscholar.org/">Go back to Semantic Scholar home page</Button>
            </Box>
        </div>
    )
};

export default MissingPage;