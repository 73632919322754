import React from 'react';
export const boldSpecificDescriptions = (text: string) => {
    const index = text.toLowerCase().indexOf('paper');
    if (index === -1) {
        return (<span>{text}</span>)
    }
    return (
        <span>
            <strong>{text.slice(0, index + 5)}</strong> {text.slice(index + 5)}
        </span>
    )
}

export const boldGeneralDescriptions = (text: string, topic: string) => {
    const index = text.toLowerCase().indexOf(topic.toLowerCase());
    if (index === -1) {
        return (<span>{text}</span>)
    }
    return (
        <span>
            {text.slice(0, index)}
            <strong>{text.slice(index, index + topic.length)}</strong> {text.slice(index + topic.length)}
        </span>
    )
}
