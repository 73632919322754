import React from 'react';

import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';

export enum PaperInteractiveState {
    QUESTION,
    THANK_YOU,
    NONE,
}

interface IsGoodDescriptionProps {
    interactiveState: PaperInteractiveState | undefined;
    handleYes: (() => void) | undefined;
    handleNo: (() => void) | undefined;
    isTopicOnly?: boolean;
}

const IsGoodDescription = ({ interactiveState, isTopicOnly, handleYes, handleNo }: IsGoodDescriptionProps) => {
    return (
        <>
        {interactiveState === PaperInteractiveState.QUESTION && (
            <span>
                Is this a good description for this topic {!isTopicOnly && ' from this paper'}?
                <Button variant="text" onClick={handleYes} data-heap-ref={`is-good-${isTopicOnly ? 'general': 'specific'}-description`}>Yes</Button>
                <Button variant="text" onClick={handleNo} data-heap-ref={`is-bad-${isTopicOnly ? 'general': 'specific'}-description`}>No</Button>
            </span>
        )}
        {interactiveState === PaperInteractiveState.THANK_YOU && (
            <Fade in={interactiveState === PaperInteractiveState.THANK_YOU}><span>Thank you for your feedback!</span></Fade>
        )}
        </>
    )
}

export default IsGoodDescription;