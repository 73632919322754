import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { Typography } from '@mui/material';

interface TooltipButtonProps {
    label: string;
    explanation?: string;
    isDarkBg?: boolean;
    labelSize?: 'small' | 'medium' | 'large';
}

const TooltipButton = ({label, explanation, isDarkBg, labelSize}: TooltipButtonProps) => {
    const [open, setOpen] = useState(false);
    const bgColor = isDarkBg ? '#F8F9FA' : '#E8ECF2';
    const textColor = isDarkBg ? '#47515C' : '#47515C';
    return (
        <ClickAwayListener onClickAway={() => setOpen(false)}>
            <Tooltip
                onClose={() => setOpen(false)}
                open={open}
                disableFocusListener
                disableHoverListener
                disableTouchListener
                title={explanation}
            >
                <Button variant="text"
                    size={
                        labelSize ? labelSize : 'small'
                    }
                    sx={{
                        marginRight: '2px',
                        backgroundColor: bgColor,
                        color: textColor,
                        fontWeight: 'normal',
                        lineHeight: '1.00'
                    }}
                    onClick={() => setOpen(!open)}
                >
                   {label}
                </Button>
            </Tooltip>
        </ClickAwayListener>
    )
}

export default TooltipButton;

