import React, {useState} from 'react';
import axios from 'axios';
import debounce from 'lodash.debounce';
import TextField from '@mui/material/TextField';
import { Autocomplete } from '@mui/material';
import { Link } from 'react-router-dom';

interface TopicSearchProps {
    apiUrl: string;
    isSmall: boolean;
}

interface SuggestionStruct {
    id: number;
    title: string;
    slug: string | null;
}

const TopicSearch: React.FC<TopicSearchProps> = ({apiUrl, isSmall}) => {
    const [suggestions, setSuggestions] = useState<SuggestionStruct[]>([]);
    const [isResultsOpen, setIsResultsOpen] = useState<boolean>(true);
    const fetchSuggestions = (query: string) => {
        axios.get(`${apiUrl}/${query}`)
            .then((response) => {
                const data = response.data;
                setSuggestions(data);
            })
            .catch((error) => {
                console.error('Error fetching suggestions:', error);
            });
    };

    const handleInput = debounce((query: string) => {
        if (!query) {
            setSuggestions([]);
            return;
        }
        fetchSuggestions(query);
    }, 500);

    return (
        <div>
            <Autocomplete
                freeSolo
                autoHighlight
                open={isResultsOpen}
                onClose={(e, v) => {
                    // @ts-ignore
                    if (e.key === 'Enter') {
                        setIsResultsOpen(true);
                    }

                    if (e.type === 'blur') {
                        setIsResultsOpen(false);
                    }
                }}

                onOpen={() => {
                  if (!isResultsOpen) {
                      setIsResultsOpen(true);
                  }
                }}
                onChange={(e, v) => {
                    // @ts-ignore
                    if (v && v.id) {
                        // @ts-ignore
                        if (window.heap) {
                            // @ts-ignore
                            window.heap.track('accept-topic-suggest', v);
                        }
                        // Delay to make sure that the event is sent to heap
                        setTimeout(() => {
                            // @ts-ignore
                            window.open(`/topic/${v.id}`, '_self')
                        }, 300);
                    }
                    // @ts-ignore
                    else if (typeof(v) === 'string' && e.key === 'Enter') {
                        // @ts-ignore
                        if (window.heap) {
                            // @ts-ignore
                            window.heap.track('enter-topic-search', { query: v} );
                        }
                    }
                }}
                options={suggestions.map((option) => ({
                    label: option.title,
                    id: option.id,
                }))}
                renderOption={(props, option) => (
                    <Link to={`/topic/${option.id}`} style={{textDecoration: 'none'}} key={`search-option-${option.id}`}>
                        <li {...props}>
                            {option.label}
                        </li>
                    </Link>
                )}
                renderInput={(params) =>
                    <TextField
                        hiddenLabel
                        {...params}
                        label="Search topics"
                        size={isSmall ? "small" : "medium"}
                        onChange={(e) => handleInput(e.target.value)}
                    />
                }
            />
        </div>
    );
};

export default TopicSearch;